import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


const muiTheme = createTheme({
  palette: {
    primary: { main: '#ab446b' },
    secondary: { main: '#464263' },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif']
  }
});


const MuiTheme = ({ children }) => <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;


export default MuiTheme;
