import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as imageEditorActions from '../editor/actions';
import * as uploadActionTypes from './actionTypes';
import * as api from './api';

export const getActiveView = state => state.imageEditorData.activeView;
export const getUserProvidedMetadata = state => state.imageEditorData.userProvidedMetadata;

/*
 * Worker Sagas.
 */
export function* uploadsFinishedSaga(action) {
  try {
    const isActive = yield select(getActiveView);
    if (!isActive) return;

    const userProvidedMetadata = yield select(getUserProvidedMetadata);
    const response = yield call(api.createImages, action.payload);

    const { realtyId, type } = action.payload;
    if (!userProvidedMetadata) {
      yield put(imageEditorActions.fetch(realtyId, type));
    } else {
      yield put(imageEditorActions.setUploadCompleted(response.data));
      yield put(imageEditorActions.fetch(realtyId, type));
    }
  } catch (e) {
    yield put(imageEditorActions.fetchError());
    console.log('Save image error', e);
  }
}

/*
 * Watcher Sagas.
 */
export function* watchUploadsFinishedSaga() {
  yield takeLatest(uploadActionTypes.UPLOAD_FINISHED, uploadsFinishedSaga);
}
