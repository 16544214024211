import { difference } from 'lodash';
import set from 'lodash/fp/set';
import type { State } from 'mepa-etuovi-common/common/types';
import type { ImageParameters } from 'mepa-etuovi-common/common/utils/createImageUrl';
import * as constants from './constants';
import type { UserProvidedMetadata, ImageEditorImageDetail, ImageRotateKey } from './types';
import { MAX_IMAGE_COUNT } from '../constants';

export const getHomeStoryImageDataForUpdateRequest = (userProvidedMetadata, imageData) => {
  const { imageDetails, desiredOrder } = userProvidedMetadata;
  const data = [];

  const itemsOrder = [];

  desiredOrder.forEach(id => {
    const imageDetail = imageDetails[id] || {};
    if (!imageDetail.removed) {
      itemsOrder.push(id);
    }
  });

  imageData.forEach(item => {
    const { REALTY_ID, IMAGE_ID, STATUS_ID } = item;
    const imageDataItem = { REALTY_ID, IMAGE_ID, STATUS_ID };

    if (itemsOrder.includes(IMAGE_ID)) {
      imageDataItem.TRANSFER_ID = itemsOrder.indexOf(IMAGE_ID) + 1;
    } else {
      imageDataItem.STATUS_ID = 2;
      imageDataItem.TRANSFER_ID = null;
    }

    const imageDetail = imageDetails[IMAGE_ID] || {};
    imageDataItem.IMAGE_DESC = imageDetail.title !== '' ? imageDetail.title : null;
    imageDataItem.ALT_TEXT = imageDetail.alternativeText !== '' ? imageDetail.alternativeText : null;

    if (imageDetail.rotate && imageDetail.rotate !== 'DEGREES_0') {
      imageDataItem.IMAGE_ROTATION = constants.IMAGE_ROTATE_MAP[imageDetail.rotate];
    }

    data.push(imageDataItem);
  });

  return data;
};

export const getImageDataForUpdateRequest = (userProvidedMetadata, imageData) => {
  const { imageDetails, desiredOrder } = userProvidedMetadata;
  const data = [];

  const internetItemsOrder = [];
  const activeItemsOrder = [];
  const archiveItemsOrder = [];
  desiredOrder.forEach(id => {
    const imageDetail = imageDetails[id] || {};
    if (!imageDetail.removed) {
      if (imageDetail.labels && imageDetail.labels.includes(constants.IMAGE_LABELS.ARCHIVE)) {
        archiveItemsOrder.push(id);
      } else if (imageDetail.labels && imageDetail.labels.includes(constants.IMAGE_LABELS.INTERNET)) {
        internetItemsOrder.push(id);
      } else {
        activeItemsOrder.push(id);
      }
    }
  });

  imageData
    .filter(image => image.ITEMIMAGETYPE_ID === 102)
    .forEach(item => {
      const { REALTY_ID, IMAGE_SOURCE_ID, STATUS_ID } = item;
      const imageDataItem = { REALTY_ID, IMAGE_SOURCE_ID, STATUS_ID };

      if (internetItemsOrder.includes(IMAGE_SOURCE_ID)) {
        imageDataItem.TRANSFER_ID = internetItemsOrder.indexOf(IMAGE_SOURCE_ID) + 1;
        imageDataItem.ARCHIVE_FLAG = 0;
      } else if (activeItemsOrder.includes(IMAGE_SOURCE_ID)) {
        imageDataItem.TRANSFER_ID = internetItemsOrder.length + activeItemsOrder.indexOf(IMAGE_SOURCE_ID) + 1;
        imageDataItem.ARCHIVE_FLAG = 0;
      } else if (archiveItemsOrder.includes(IMAGE_SOURCE_ID)) {
        imageDataItem.TRANSFER_ID =
          internetItemsOrder.length + activeItemsOrder.length + archiveItemsOrder.indexOf(IMAGE_SOURCE_ID) + 1;
        imageDataItem.ARCHIVE_FLAG = 1;
      } else {
        imageDataItem.STATUS_ID = 2;
        imageDataItem.IMAGE_IV_SELECT_FLAG = 0;
        imageDataItem.TRANSFER_ID = null;
        imageDataItem.REALTYIMAGETYPE_ID = 2;
      }

      const imageDetail = imageDetails[IMAGE_SOURCE_ID] || {};
      imageDataItem.IMAGE_DESC = imageDetail.title !== '' ? imageDetail.title : null;
      imageDataItem.ALT_TEXT = imageDetail.alternativeText !== '' ? imageDetail.alternativeText : null;

      if (imageDetail.labels && imageDetail.labels.includes(constants.IMAGE_LABELS.INTERNET)) {
        imageDataItem.IMAGE_IV_SELECT_FLAG = 1;
        imageDataItem.REALTYIMAGETYPE_ID = internetItemsOrder[0] === IMAGE_SOURCE_ID ? 1 : 2;
      } else {
        imageDataItem.IMAGE_IV_SELECT_FLAG = 0;
        imageDataItem.REALTYIMAGETYPE_ID = 2;
      }

      if (imageDetail.labels && imageDetail.labels.includes(constants.IMAGE_LABELS.FLOOR_PLAN)) {
        imageDataItem.REALTYIMAGETYPE_ID = 3;
      }

      if (imageDetail.rotate && imageDetail.rotate !== 'DEGREES_0') {
        imageDataItem.IMAGE_ROTATION = constants.IMAGE_ROTATE_MAP[imageDetail.rotate];
      }

      data.push(imageDataItem);
    });

  return data;
};

export const isImageDataInSync = (fetchedImageData, localImageData) => {
  const fetchedIds = fetchedImageData
    .filter(image => image.ITEMIMAGETYPE_ID === 102)
    .map(item => item.IMAGE_SOURCE_ID);

  return difference(fetchedIds, localImageData
    .map(item => item.IMAGE_SOURCE_ID)).length === 0;
};

export const convertBinaries = imageData => {
  const binaries = {};
  const filtered = imageData.filter(image => image.ITEMIMAGETYPE_ID === 102);

  if (filtered.length > 0) {
    filtered.forEach(image => {
      binaries[image.IMAGE_SOURCE_ID] = {
        imageSourceId: image.IMAGE_SOURCE_ID,
        imageProxyUrlTemplate: image.IMAGE_URL,
      };
    });
  } else {
    imageData.forEach(image => {
      binaries[image.IMAGE_ID] = {
        imageSourceId: image.IMAGE_ID,
        imageProxyUrlTemplate: image.IMAGE_URL,
      };
    });
  }

  return binaries;
};

export const convertLabels = imageDataItem => {
  const labels = [];

  if (imageDataItem && imageDataItem.IMAGE_IV_SELECT_FLAG === 1) {
    labels.push(constants.IMAGE_LABELS.INTERNET);
  }

  if (imageDataItem && imageDataItem.REALTYIMAGETYPE_ID === 3) {
    labels.push(constants.IMAGE_LABELS.FLOOR_PLAN);
  }

  if (imageDataItem && imageDataItem.ARCHIVE_FLAG === 1) {
    labels.push(constants.IMAGE_LABELS.ARCHIVE);
  }

  return labels;
};

export const imageDataCompare = (image1, image2) => {
  if (image1.ARCHIVE_FLAG === image2.ARCHIVE_FLAG && image1.TRANSFER_ID === image2.TRANSFER_ID) {
    return 0;
  }

  if (image1.ARCHIVE_FLAG < image2.ARCHIVE_FLAG) {
    return -1;
  }

  if (image1.ARCHIVE_FLAG > image2.ARCHIVE_FLAG) {
    return 1;
  }

  if (!image1.TRANSFER_ID && !image2.TRANSFER_ID) {
    return 0;
  }

  if (!image2.TRANSFER_ID || (image1.TRANSFER_ID > 0 && image1.TRANSFER_ID < image2.TRANSFER_ID)) {
    return -1;
  }

  if (!image1.TRANSFER_ID || (image2.TRANSFER_ID > 0 && image1.TRANSFER_ID > image2.TRANSFER_ID)) {
    return 1;
  }

  return 0;
};

export const imageDataSimpleCompare = (image1, image2) => {
  if (image1.TRANSFER_ID === image2.TRANSFER_ID) {
    return 0;
  }

  if (!image1.TRANSFER_ID && !image2.TRANSFER_ID) {
    return 0;
  }

  if (!image2.TRANSFER_ID || (image1.TRANSFER_ID > 0 && image1.TRANSFER_ID < image2.TRANSFER_ID)) {
    return -1;
  }

  if (!image1.TRANSFER_ID || (image2.TRANSFER_ID > 0 && image1.TRANSFER_ID > image2.TRANSFER_ID)) {
    return 1;
  }

  return 0;
};

export const convertUserProvided = imageData => {
  const imageDetails = {};
  const desiredOrder = [];

  const webImageData = imageData.filter(image => image.ITEMIMAGETYPE_ID === 102);

  if (webImageData.length > 0) {
    webImageData.sort(imageDataCompare);

    webImageData.forEach(image => {
      imageDetails[image.IMAGE_SOURCE_ID] = {
        imageSourceId: image.IMAGE_SOURCE_ID,
        title: image.IMAGE_DESC || '',
        alternativeText: image.ALT_TEXT || '',
        labels: convertLabels(image),
        brochureFlag: image.BROCHURES && image.BROCHURES.length > 0,
        windowCardFlag: image.WINDOWCARDS && image.WINDOWCARDS.length > 0,
        removed: false,
        originSource: image.ORIGIN_SOURCE
      };

      desiredOrder.push(image.IMAGE_SOURCE_ID);
    });
  } else {
    imageData.sort(imageDataSimpleCompare);
    imageData.forEach(image => {
      imageDetails[image.IMAGE_ID] = {
        imageSourceId: image.IMAGE_ID,
        title: image.IMAGE_DESC || '',
        alternativeText: image.ALT_TEXT || '',
        labels: [],
        brochureFlag: false,
        windowCardFlag: false,
        removed: false,
        originSource: image.ORIGIN_SOURCE
      };

      desiredOrder.push(image.IMAGE_ID);
    });
  }

  return {
    imageDetails,
    desiredOrder,
  };
};

// Updates whole imageDetailsObject and returns new state
export const updateImageDetails = (imageDetail: ImageEditorImageDetail, state: State) =>
  set(['userProvidedMetadata', 'imageDetails', imageDetail.imageSourceId], imageDetail, state);

/* Rotate image */
export const getNextRotateValue = (rotate: ?ImageRotateKey = 'DEGREES_0'): ImageRotateKey => {
  const rotateArray = Object.keys(constants.IMAGE_ROTATE_MAP);

  const i = rotateArray.indexOf(rotate) + 1;
  const newIndex = i < rotateArray.length ? i : 0;
  return rotateArray[newIndex];
};

/* Conver thumbnailImage options */
export const thumbnailOptions = (): ImageParameters => ({
  width: constants.THUMBNAIL_WIDTH,
  height: constants.THUMBNAIL_HEIGHT,
  quality: constants.THUMBNAIL_QUALITY,
  rotation: constants.THUMBNAIL_ROTATION,
});

export const getRotateClass = (imageDetail: ImageEditorImageDetail) => {
  return imageDetail && imageDetail.rotate ? `rotate${constants.IMAGE_ROTATE_MAP[imageDetail.rotate]}` : 'rotate0';
};

export const getRotateStyle = (imageDetail: ImageEditorImageDetail, dimensions: ImageDimensions) => {
  if (
    dimensions &&
    (constants.IMAGE_ROTATE_MAP[imageDetail.rotate] === 90 || constants.IMAGE_ROTATE_MAP[imageDetail.rotate] === 270)
  ) {
    const { height, width } = dimensions;
    const newHeight = Math.round((height * height) / width);
    const style = {
      width: `${height}px`,
      height: `${newHeight}px`,
    };

    if (constants.IMAGE_ROTATE_MAP[imageDetail.rotate] === 90) {
      if (height - newHeight < 0) {
        style.paddingRight = `${-1 * (height - newHeight)}px`;
      } else {
        style.paddingLeft = `${height - newHeight}px`;
      }
    } else if (height - newHeight < 0) {
      style.paddingLeft = `${-1 * (height - newHeight)}px`;
    } else {
      style.paddingRight = `${height - newHeight}px`;
    }

    return style;
  }

  return null;
};

export const getCurrentImageDetailWithLabel = (metadata: UserProvidedMetadata, imageLabel: string) => {
  const { imageDetails, desiredOrder } = metadata;
  let foundImageDetail = null;

  if (desiredOrder) {
    desiredOrder.forEach(key => {
      const imageDetail = imageDetails[key];
      if (imageDetail.labels && imageDetail.labels.filter(label => label === imageLabel).length > 0) {
        foundImageDetail = imageDetail;
      }
    });
  }

  return foundImageDetail;
};

export const addStatusLabel = (labels, imageLabel) => {
  const updatedLabels = labels
    ? labels.filter(label => label !== constants.IMAGE_LABELS.INTERNET && label !== constants.IMAGE_LABELS.ARCHIVE)
    : [];

  if (imageLabel && imageLabel !== '') {
    updatedLabels.push(imageLabel);
  }

  return updatedLabels.length > 0 ? updatedLabels : null;
};

export const updateImagesToPageAndCloseOverlay = (uploadOrSaveDone, imageData) => {
  const imageContainer = document.getElementById('imageContainer');
  if (uploadOrSaveDone && imageData && imageContainer !== null) {
    let imageHtml = '';
    imageData.sort(imageDataCompare);

    const internetItems = imageData.filter(
      image => image.ITEMIMAGETYPE_ID === 102 && image.ARCHIVE_FLAG === 0 && image.IMAGE_IV_SELECT_FLAG === 1
    );
    if (internetItems.length > 0) {
      imageHtml += '<div><span class="boldText">Valittu Internet-näkyvyyteen</span>';

      internetItems.forEach(image => {
        imageHtml += `<a href="${image.IMAGE_URL}" target="_blank"><img src="${image.IMAGE_URL}" /></a> `;
      });

      imageHtml += '</div>';
    }

    const active = imageData.filter(
      image => image.ITEMIMAGETYPE_ID === 102 && image.ARCHIVE_FLAG === 0 && image.IMAGE_IV_SELECT_FLAG === 0
    );
    if (active.length > 0) {
      imageHtml += '<div><span class="boldText">Ei valittuna Internet-näkyvyyteen</span>';

      active.forEach(image => {
        imageHtml += `<a href="${image.IMAGE_URL}" target="_blank"><img src="${image.IMAGE_URL}" /></a> `;
      });

      imageHtml += '</div>';
    }

    const archived = imageData.filter(image => image.ITEMIMAGETYPE_ID === 102 && image.ARCHIVE_FLAG === 1);
    if (archived.length > 0) {
      imageHtml += '<div><span class="boldText">Arkistokuvat</span>';

      archived.forEach(image => {
        imageHtml += `<a href="${image.IMAGE_URL}" target="_blank"><img src="${image.IMAGE_URL}" /></a> `;
      });

      imageHtml += '</div>';
    }

    imageContainer.innerHTML = imageHtml;

    updateImageButtons(imageData);
  }

  document.body.classList.remove('overlay-open');
  window.location.hash = 'IMAGES';
};

const updateImageButtons = imageData => {
  const imageInfo = document.getElementById('imageInfo');
  const images = imageData.filter(image => image.ITEMIMAGETYPE_ID === 102);

  if (imageInfo !== null) {
    const imageAddButton = document.getElementById('imageAddButton');

    if (images.length >= MAX_IMAGE_COUNT) {
      imageInfo.innerHTML =
        images.length === MAX_IMAGE_COUNT
          ? `<span class="basicIndent">Kuvia lisättynä maksimimäärä:  <b>${MAX_IMAGE_COUNT}</b></span>`
          : `<span class="basicIndent">Kuvien määrä (<b>${images.length}</b>) ylittää sallitun maksimimäärän: <b>${MAX_IMAGE_COUNT}</b></span>`;

      if (imageAddButton !== null) {
        imageAddButton.className = 'basicButtonNewDisabled';
        imageAddButton.disabled = true;
      }
    } else {
      imageInfo.innerHTML = '';

      if (imageAddButton !== null) {
        imageAddButton.className = 'basicButtonNew';
        imageAddButton.disabled = false;
      }
    }
  }

  const imageEditButton = document.getElementById('imageEditButton');
  if (imageEditButton !== null) {
    imageEditButton.className = 'basicButtonNew';
    imageEditButton.disabled = false;
  }

  const imageCount = document.getElementById('imageCount');
  if (imageCount !== null) {
    imageCount.value = images.length;
  }
};

export const setInternetLabel = (imageDetails, internetVisibility) => {
  if (imageDetails) {
    Object.keys(imageDetails).forEach(key => {
      const imageDetail = imageDetails[key] || {};
      if (internetVisibility) {
        if (
          imageDetail.labels &&
          !imageDetail.labels.includes(constants.IMAGE_LABELS.ARCHIVE) &&
          !imageDetail.labels.includes(constants.IMAGE_LABELS.INTERNET)
        ) {
          imageDetail.labels.push(constants.IMAGE_LABELS.INTERNET);
        } else if (!imageDetail.labels || imageDetail.labels.length === 0) {
          imageDetail.labels = [constants.IMAGE_LABELS.INTERNET];
        }
      } else if (imageDetail.labels) {
        imageDetail.labels = imageDetail.labels.filter(label => label !== constants.IMAGE_LABELS.INTERNET);
      }
    });
  }

  return imageDetails;
};

export const getValidImageData = data => {
  if (!data) return null;

  if (data.length > 0 && data[0].IMAGE_SOURCE_ID) {
    return data.map(item => ({
      ...item,
      IMAGE_ID: Number(item.IMAGE_ID),
      REALTY_ID: Number(item.REALTY_ID),
      IMAGE_SOURCE_ID: Number(item.IMAGE_SOURCE_ID),
      ITEMIMAGETYPE_ID: Number(item.ITEMIMAGETYPE_ID),
      STATUS_ID: Number(item.STATUS_ID),
      TRANSFER_ID: Number(item.TRANSFER_ID),
      REALTYIMAGETYPE_ID: Number(item.REALTYIMAGETYPE_ID),
      ARCHIVE_FLAG: Number(item.ARCHIVE_FLAG),
      IMAGE_IV_SELECT_FLAG: Number(item.IMAGE_IV_SELECT_FLAG),
    }));
  }

  return data.map(item => ({
    ...item,
    IMAGE_ID: Number(item.IMAGE_ID),
    REALTY_ID: Number(item.REALTY_ID),
    STATUS_ID: Number(item.STATUS_ID),
    TRANSFER_ID: Number(item.TRANSFER_ID),
  }));
};

export const isS3Image = imageUrl => {
  return imageUrl && imageUrl.includes('cloudfront.net');
};
