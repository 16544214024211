import axios from 'axios';
import { TYPE } from '../constants';

export const createImages = requestData => {
  const { realtyId, imageKeys, type } = requestData;
  return axios.request({
    url: type === TYPE.HOME_STORY ? `/ekiviapi/homestory/${realtyId}/image` : `/ekiviapi/realtydata/${realtyId}/image`,
    data: imageKeys,
    method: 'POST',
  });
};
