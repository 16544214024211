import axios from 'axios';
import { TYPE } from '../constants';

export const updateMetadata = requestData => {
  const { realtyId, imageData, type } = requestData;
  return axios.request({
    url: type === TYPE.HOME_STORY ? `/ekiviapi/homestory/${realtyId}/image` : `/ekiviapi/realtydata/${realtyId}/image`,
    data: imageData,
    method: 'PUT',
  });
};

export const fetchImages = requestData => {
  const { realtyId, type } = requestData;
  return axios.request({
    url: type === TYPE.HOME_STORY ? `/ekiviapi/homestory/${realtyId}/image` : `/ekiviapi/realtydata/${realtyId}/image`,
    method: 'GET',
  });
};
