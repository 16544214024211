import { NAME } from './constants';

export const ROTATE_IMAGE = `${NAME}/ROTATE_IMAGE`;
export const SET_IMAGE_DIMENSIONS = `${NAME}/SET_IMAGE_DIMENSIONS`;
export const TOGGLE_REMOVE_IMAGE = `${NAME}/TOGGLE_REMOVE_IMAGE`;
export const UPDATE_ORDER = `${NAME}/UPDATE_ORDER`;
export const SET_DESCRIPTION = `${NAME}/SET_DESCRIPTION`;
export const TOGGLE_LABEL = `${NAME}/TOGGLE_LABEL`;
export const SET_STATUS_LABEL = `${NAME}/SET_STATUS_LABEL`;
export const SET_SINGLE_OPTION_LABEL = `${NAME}/SET_SINGLE_OPTION_LABEL`;
export const SET_TITLE = `${NAME}/SET_TITLE`;
export const SET_ALTERNATIVE_TEXT = `${NAME}/SET_ALTERNATIVE_TEXT`;
export const SET_INTERNET_TO_ALL = `${NAME}/SET_INTERNET_TO_ALL`;

export const FETCH_IMAGES = `${NAME}/FETCH_IMAGES`;
export const FETCHING_IMAGES_COMPLETE = `${NAME}/FETCHING_IMAGES_COMPLETE`;
export const FETCHING_IMAGES_FAILED = `${NAME}/FETCHING_IMAGES_FAILED`;

export const UPDATE_METADATA = `${NAME}/UPDATING_METADATA`;
export const UPDATING_METADATA_COMPLETE = `${NAME}/UPDATING_METADATA_COMPLETE`;
export const UPDATING_METADATA_FAILED = `${NAME}/UPDATING_METADATA_FAILED`;
export const DATA_NOT_IN_SYNC_UPDATE_FAILED = `${NAME}/DATA_NOT_IN_SYNC_UPDATE_FAILED`;

export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW';
export const SET_UPLOAD_COMPLETED = 'SET_UPLOAD_COMPLETED';
export const SET_MOUSEOVER_SUPPORT = 'SET_MOUSEOVER_SUPPORT';
export const SET_TOUCHSCREEN = 'SET_TOUCHSCREEN';
