import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as api from './api';
import * as editorActions from './actions';
import * as t from './actionTypes';
import * as utils from './utils';
import { TYPE } from '../constants';

export const getUserProvidedMetadata = state => state.imageEditorData.userProvidedMetadata;
export const getImageData = state => state.imageEditorData.imageData;

/*
 * Worker Sagas.
 */
export function* loadImagesSaga(action) {
  try {
    const response = yield call(api.fetchImages, action.payload);

    yield put(editorActions.fetchSuccess(response.data));
  } catch (e) {
    yield put(editorActions.fetchError());
    console.log('Error while fetching images', e);
  }
}

export function* updateMetadataSaga(action) {
  try {
    const { userProvidedMetadata, realtyId, type } = action.payload;
    const imageData = yield select(getImageData);
    const requestImageData =
      type === TYPE.HOME_STORY
        ? utils.getHomeStoryImageDataForUpdateRequest(userProvidedMetadata, imageData, type)
        : utils.getImageDataForUpdateRequest(userProvidedMetadata, imageData, type);

    const fetchedImages = yield call(api.fetchImages, action.payload);
    if (!utils.isImageDataInSync(fetchedImages.data, requestImageData)) {
      yield put(editorActions.dataNotInSyncError());
      console.log('Error: Image data is not in sync');

    } else {
      const response = yield call(api.updateMetadata, { imageData: requestImageData, realtyId, type });
      yield put(editorActions.updateMetadataSuccess(response.data));
      window.location.hash = '';
    }

  } catch (e) {
    yield put(editorActions.updateMetadataError());
    console.log('Error while updating metadatas', e);
  }
}

export function* handleFloorPlanLabelsSaga(action) {
  const { imageLabel, imageDetail } = action.payload;
  const userProvidedMetadata = yield select(getUserProvidedMetadata);
  const currentImageDetail = utils.getCurrentImageDetailWithLabel(userProvidedMetadata, imageLabel);

  if (currentImageDetail) {
    yield put(editorActions.toggleLabel({ imageDetail: currentImageDetail, imageLabel }));
  }
  if (imageDetail) {
    yield put(editorActions.toggleLabel({ imageDetail, imageLabel }));
  }
}

/*
 * Watcher Sagas.
 */
export function* watchLoadImagesSaga() {
  yield takeLatest(t.FETCH_IMAGES, loadImagesSaga);
}

export function* watchUpdateMetadataSaga() {
  yield takeLatest(t.UPDATE_METADATA, updateMetadataSaga);
}

export function* watchHandleFloorPlanLabels() {
  yield takeLatest(t.SET_SINGLE_OPTION_LABEL, handleFloorPlanLabelsSaga);
}
