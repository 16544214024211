import * as t from './actionTypes';
import * as uploadActionTypes from '../upload/actionTypes';
import * as utils from './utils';
import { ERROR, IMAGE_LABELS } from './constants';

export const initialState = {
  realtyId: 'no-such-realty-exists',
  binaries: null,
  userProvidedMetadata: null,
  imageData: null,
  activeView: null,
  fetchingData: false,
  uploadOrSaveDone: false,
  error: null,
  imageDimensions: null,
  mouseoverSupport: false,
  touchcreen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SET_ACTIVE_VIEW: {
      const activeView = action.payload;

      if (!activeView) {
        const { uploadOrSaveDone, imageData } = state;
        utils.updateImagesToPageAndCloseOverlay(uploadOrSaveDone, imageData);

        return {
          ...state,
          activeView,
          binaries: null,
          userProvidedMetadata: null,
          imageData: null,
        };
      }

      document.body.classList.add('overlay-open');
      return {
        ...state,
        activeView,
      };
    }

    case t.SET_UPLOAD_COMPLETED: {
      const { data } = action.payload;
      const oldData = state.imageData || [];
      const imageData = oldData.concat(utils.getValidImageData(data));

      return {
        ...state,
        fetchingData: false,
        imageData,
        binaries: imageData ? utils.convertBinaries(imageData) : null,
        userProvidedMetadata: imageData ? utils.convertUserProvided(imageData) : null,
      };
    }

    case t.FETCH_IMAGES: {
      const { realtyId } = action.payload;
      return {
        ...state,
        realtyId,
        fetchingData: true,
        error: null,
      };
    }

    case t.FETCHING_IMAGES_COMPLETE: {
      const { data } = action.payload;
      const imageData = data ? data.filter(item => item.STATUS_ID === 1) : null;
      return {
        ...state,
        fetchingData: false,
        imageData,
        binaries: imageData ? utils.convertBinaries(imageData) : null,
        userProvidedMetadata: imageData ? utils.convertUserProvided(imageData) : null,
      };
    }

    case t.FETCHING_IMAGES_FAILED: {
      return {
        ...state,
        fetchingData: false,
        error: ERROR.FETCHING_IMAGES_FAILED,
      };
    }

    case t.UPDATE_METADATA: {
      return {
        ...state,
        fetchingData: true,
        error: null,
      };
    }

    case t.UPDATING_METADATA_COMPLETE: {
      const { data } = action.payload;
      const imageData = data ? data.filter(item => item.STATUS_ID === 1) : null;
      return {
        ...state,
        fetchingData: false,
        imageData,
        activeView: null,
        binaries: null,
        userProvidedMetadata: null,
        uploadOrSaveDone: true,
      };
    }

    case t.UPDATING_METADATA_FAILED: {
      return {
        ...state,
        fetchingData: false,
        error: ERROR.UPDATING_METADATA_FAILED,
      };
    }

    case t.DATA_NOT_IN_SYNC_UPDATE_FAILED: {
      return {
        ...state,
        fetchingData: false,
        error: ERROR.DATA_NOT_IN_SYNC_UPDATE_FAILED,
      };
    }

    case t.ROTATE_IMAGE: {
      const updatedImageDetail = {
        ...action.payload.imageDetail,
        rotate: utils.getNextRotateValue(action.payload.imageDetail.rotate),
      };
      return utils.updateImageDetails(updatedImageDetail, state);
    }

    case t.TOGGLE_REMOVE_IMAGE: {
      const { imageDetail } = action.payload;
      const { removed, labels } = imageDetail;
      return utils.updateImageDetails(
        {
          ...imageDetail,
          removed: !removed,
          labels: labels && !removed ? labels.filter(label => label !== IMAGE_LABELS.FLOOR_PLAN) : labels,
        },
        state
      );
    }

    case t.SET_TITLE: {
      const { title } = action.payload;
      return utils.updateImageDetails(
        {
          ...action.payload.imageDetail,
          title,
        },
        state
      );
    }

    case t.SET_ALTERNATIVE_TEXT: {
      const { alternativeText } = action.payload;
      return utils.updateImageDetails(
        {
          ...action.payload.imageDetail,
          alternativeText,
        },
        state
      )
    }

    case t.SET_STATUS_LABEL: {
      const { imageDetail, imageLabel } = action.payload;
      return utils.updateImageDetails(
        {
          ...imageDetail,
          labels: utils.addStatusLabel(imageDetail.labels, imageLabel),
        },
        state
      );
    }

    case t.TOGGLE_LABEL: {
      const { imageDetail, imageLabel } = action.payload;
      let updatedImageLabel;

      if (imageDetail.labels && imageDetail.labels.includes(imageLabel)) {
        updatedImageLabel = {
          ...imageDetail,
          labels: imageDetail.labels.length > 1 ? imageDetail.labels.filter(label => label !== imageLabel) : null,
        };
      } else if (!imageDetail.labels) {
        updatedImageLabel = {
          ...imageDetail,
          labels: [imageLabel],
        };
      } else {
        updatedImageLabel = {
          ...imageDetail,
          labels: imageDetail.labels.concat([imageLabel]),
        };
      }
      return utils.updateImageDetails(updatedImageLabel, state);
    }

    case t.UPDATE_ORDER: {
      const { desiredOrder } = action.payload;
      return {
        ...state,
        userProvidedMetadata: {
          ...state.userProvidedMetadata,
          desiredOrder,
        },
      };
    }

    case t.SET_IMAGE_DIMENSIONS: {
      const { dimensions, imageDetail } = action.payload;
      return {
        ...state,
        imageDimensions: {
          ...state.imageDimensions,
          [imageDetail.imageSourceId]: dimensions,
        },
      };
    }

    case t.SET_INTERNET_TO_ALL: {
      const internetVisibility = action.payload;
      const { imageDetails, desiredOrder } = state.userProvidedMetadata;
      return {
        ...state,
        userProvidedMetadata: {
          imageDetails: utils.setInternetLabel(imageDetails, internetVisibility),
          desiredOrder,
        },
      };
    }

    case uploadActionTypes.UPLOAD_FINISHED: {
      return {
        ...state,
        uploadOrSaveDone: true,
        fetchingData: true,
        error: null,
      };
    }

    case t.SET_MOUSEOVER_SUPPORT: {
      return {
        ...state,
        mouseoverSupport: true,
      };
    }

    case t.SET_TOUCHSCREEN: {
      return {
        ...state,
        touchscreen: true,
        mouseoverSupport: false,
      };
    }

    default:
      return state;
  }
};
