const defaultTheme = {
  brandColor: '#ab446b',
  brandColorDark: '#ab446b',
  brandLighter: '#c9a7c6',
  brandWhite: 'white',
  fontFamily: 'Open Sans',
  weight: 400,
  semiBold: 600,
  extraBold: 800,
  secondaryGrey: '#D0CFD7',
  secondaryDarkGrey: '#929ba4',
};

const themes = {
  DEFAULT: defaultTheme,

  SOME_OTHER: {
    ...defaultTheme,
    brandColor: 'purple',
  },
};

const theme = {
  ...defaultTheme,
  set(themeName = 'DEFAULT') {
    Object.assign(this, themes[themeName]);
  },
};


export default theme;
