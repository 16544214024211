import React from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-61691409-1');

const GAEventTracker = (category, action, label) => {
    if (process.env.REACT_APP_ENV === 'production') ReactGA.event({ category, action, label });
    else console.log('NonProductionGAEventTracker', { category, action, label });
};

const GoogleAnalytics = ({ ...props }) => <div>{React.Children.map(props.children, child => React.cloneElement(child, { ...props, ReactGA: { event: GAEventTracker } }))}</div>

export default GoogleAnalytics;