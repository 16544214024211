export const VIEW = {
  UPLOAD: 'UPLOAD',
  EDITOR: 'EDITOR',
};

export const TYPE = {
  HOME_STORY: 'homeStory',
  REALTY: 'realty',
};

export const MAX_IMAGE_COUNT = 200;
