import 'core-js/features/array/find';
import 'core-js/features/number/is-nan';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import theme from './theme';
import MuiTheme from './MuiTheme';
import GoogleAnalytics from './GoogleAnalytics';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import './utils/polyfills';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

async function renderComponent(el) {
  console.log('Rendering component', el.id);

  // eslint-disable-next-line
  const { theme_name, ...props } = Object.assign({}, el.dataset);
  theme.set(theme_name);

  const idx = el.id ? el.id.lastIndexOf('_') : 0;
  const elId = idx > 0 ? el.id.substring(0, idx) : el.id;

  let Comp;
  try {
    switch (elId) {
      case 'KiviLogin':
        Comp = (await import('./kiviLogin/KiviLogin')).default;
        break;
      case 'EoProductPage':
        Comp = (await import('./eoProducts/EoProductPage')).default;
        break;
      case 'EoProductHistory':
        Comp = (await import('./eoProducts/EoProductHistory')).default;
        break;
      case 'GdprCustomers':
        Comp = (await import('./gdprCustomers/GdprCustomers')).default;
        break;
      case 'Images':
        Comp = (await import('./images/Images')).default;
        break;
      case 'Dias':
        Comp = (await import('./dias/Dias')).default;
        break;
      case 'DiasRealty':
        Comp = (await import('./dias/realty/Dias')).default;
        break;
      case 'EKiviLogin':
        Comp = (await import('./components/EKiviLogin')).default;
        break;
      case 'PdfDownload':
        Comp = (await import('./components/PdfDownload')).default;
        break;
      case 'HomeStory':
        Comp = (await import('./realiaHomeStory/HomeStory')).default;
        break;
      case 'TransferTax':
        Comp = (await import('./transferTax/TransferTax')).default;
        break;
      case 'AreaTagsInput':
        Comp = (await import('./assignmentPurchase/AreaTagsInput')).default;
        break;
      case 'TietopalvelutDocuments':
        Comp = (await import('./tietopalvelut/TietopalvelutDocuments')).default;
        break;
      case 'TietopalvelutRealtyidentifierFetcher':
        Comp = (await import('./tietopalvelut/TietopalvelutRealtyidentifierFetcher')).default;
        break;
      case 'TietopalvelutRealtyidentifierFetcherDocs':
        Comp = (await import('./tietopalvelut/TietopalvelutRealtyidentifierFetcher')).default;
        break;
      case 'TietopalvelutShareGroupIdFetcher':
        Comp = (await import('./tietopalvelut/TietopalvelutShareGroupIdFetcher')).default;
        break;
      case 'TietopalvelutCheckShareRegisterHTJ':
        Comp = (await import('./tietopalvelut/TietopalvelutCheckShareRegisterHTJ')).default;
        break;
      case 'TietopalvelutBusinessIdFetcher':
        Comp = (await import('./tietopalvelut/TietopalvelutBusinessIdFetcher')).default;
        break;
      case 'CustomField':
        Comp = (await import('./customField/CustomField')).default;
        break;
      case 'Zentuvo':
        Comp = (await import('./zentuvo/Zentuvo')).default;
        break;
      case 'Minimapper':
        Comp = (await import('./minimapper/Minimapper')).default;
        break;
      default:
        console.log('Unknown component', el.id);
        return;
    }
  } catch (error) {
    console.log('Error importing component.', error);
    Comp = <div className="error">Käyttöliittymäkomponentin lataaminen epäonnistui. Yritä myöhemmin uudelleen.</div>;
  }

  ReactDOM.render(
    <MuiTheme>
      <Provider store={store}>
        <GoogleAnalytics>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Comp {...props} />
        </GoogleAnalytics>
      </Provider>
    </MuiTheme>,
    el
  );
}

function renderComponents() {
  console.log('Loading react components...');
  Array.prototype.forEach.call(document.querySelectorAll('.__react-root'), renderComponent);
  Array.prototype.forEach.call(document.querySelectorAll('.__react-root2'), renderComponent);
}

if (document.readyState !== 'loading') renderComponents();
else document.addEventListener('DOMContentLoaded', renderComponents);
