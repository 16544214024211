import type { ImageRotateMap, OriginSource } from './types';

/* Feature name */
export const NAME = 'imageEditor';

/* Image labels */
export const IMAGE_LABELS = {
  FLOOR_PLAN: 'FLOOR_PLAN',
  INTERNET: 'INTERNET',
  ARCHIVE: 'ARCHIVE',
};

/* Image rotation */
export const IMAGE_ROTATE_MAP: ImageRotateMap = {
  DEGREES_0: 0,
  DEGREES_90: 90,
  DEGREES_180: 180,
  DEGREES_270: 270,
};

export const ORIGIN_SOURCE: OriginSource = {
  ZENTUVO: 'ZENTUVO',
  EXTAPI: 'EXTAPI',
  KIVI: 'KIVI'
};

/* Errors */
export const ERROR = {
  FETCHING_IMAGES_FAILED: 'FETCHING_IMAGES_FAILED',
  UPDATING_METADATA_FAILED: 'UPDATING_METADATA_FAILED',
  DATA_NOT_IN_SYNC_UPDATE_FAILED: 'DATA_NOT_IN_SYNC_UPDATE_FAILED'
};

/* Thumbnail settings */
export const THUMBNAIL_RATIO = 3 / 2;
export const THUMBNAIL_WIDTH = 320;
export const THUMBNAIL_HEIGHT = Math.round(THUMBNAIL_WIDTH / THUMBNAIL_RATIO);
export const THUMBNAIL_QUALITY = 65;
export const THUMBNAIL_ROTATION = 0;
