// @flow
import * as t from './actionTypes';
import type {
  DesiredOrder,
  MetadataUpdateRequestData,
  OnRotateImageAction,
  OnRemoveImageAction,
  OnToggleLabelAction,
  OnChangeLabelAction,
  OnSetImageTitleAction,
  OnSetImageAlternativeTextAction,
  OnImageLoadAction,
} from './types';

export const setActiveView = (payload: string) => ({
  type: t.SET_ACTIVE_VIEW,
  payload,
});

export const rotateImage = (payload: OnRotateImageAction) => ({
  type: t.ROTATE_IMAGE,
  payload,
});

export const setImageDimensions = (payload: OnImageLoadAction) => ({
  type: t.SET_IMAGE_DIMENSIONS,
  payload,
});

export const toggleRemoveImage = (payload: OnRemoveImageAction) => ({
  type: t.TOGGLE_REMOVE_IMAGE,
  payload,
});

export const sortImages = (desiredOrder: DesiredOrder) => ({
  type: t.UPDATE_ORDER,
  payload: { desiredOrder },
});

export const toggleLabel = (payload: OnToggleLabelAction) => ({
  type: t.TOGGLE_LABEL,
  payload,
});

export const changeStatusLabel = (payload: OnChangeLabelAction) => ({
  type: t.SET_STATUS_LABEL,
  payload,
});

export const setSingleOptionLabel = (payload: OnToggleLabelAction) => ({
  type: t.SET_SINGLE_OPTION_LABEL,
  payload,
});

export const setImageTitle = (payload: OnSetImageTitleAction) => ({
  type: t.SET_TITLE,
  payload,
});

export const setImageAlternativeText = (payload: OnSetImageAlternativeTextAction) => ({
  type: t.SET_ALTERNATIVE_TEXT,
  payload,
});

export const fetch = (realtyId: string, type: string) => ({
  type: t.FETCH_IMAGES,
  payload: { realtyId, type },
});

export const fetchSuccess = data => ({
  type: t.FETCHING_IMAGES_COMPLETE,
  payload: { data },
});

export const fetchError = () => ({
  type: t.FETCHING_IMAGES_FAILED,
});

export const updateMetadata = (data: MetadataUpdateRequestData) => ({
  type: t.UPDATE_METADATA,
  payload: data,
});

export const updateMetadataSuccess = data => ({
  type: t.UPDATING_METADATA_COMPLETE,
  payload: { data },
});

export const updateMetadataError = () => ({
  type: t.UPDATING_METADATA_FAILED,
});

export const dataNotInSyncError = () => ({
  type: t.DATA_NOT_IN_SYNC_UPDATE_FAILED,
});

export const setInternetToAll = value => ({
  type: t.SET_INTERNET_TO_ALL,
  payload: value,
});

export const setUploadCompleted = data => ({
  type: t.SET_UPLOAD_COMPLETED,
  payload: { data },
});

export const setMouseoverSupport = () => ({
  type: t.SET_MOUSEOVER_SUPPORT,
});

export const setTouchscreen = () => ({
  type: t.SET_TOUCHSCREEN,
});
